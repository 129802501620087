import styled from '@emotion/styled'
import { MotionStyle } from 'framer-motion'
import { Component } from 'react'
import { ConfigurationDetail, IdentifierType } from 'stylewhere/api'
import { Box, Icons, Text, Button, Input } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { Spinner } from '@chakra-ui/react'

interface Props {
  marginBottom?: number
  widthPerc?: number
  inLine?: boolean
  identifier: ConfigurationDetail
  style?: MotionStyle
  buttons?: { text: string; onClick: () => void }[]
  onInputSubmit?: (value: string) => void
  layout?: any
  isAssociation?: boolean
  identifiersCount?: number
  labelsByType?: Partial<Record<IdentifierType, string>>
  hideCode?: boolean
}

export class IdentifierBox extends Component<Props> {
  textColor = {
    ERROR: 'white',
    NFC_COLLISION: 'white',
    DEFAULT: 'black',
  }

  identifierTypes = {
    UHF_TAG: { icon: (props) => <Icons.Rfid {...props} />, text: 'TAG UHF' },
    NFC_TAG: { icon: (props) => <Icons.Nfc {...props} />, text: 'TAG NFC' },
    SIMPLE_ITEM_IDENTIFIER: { icon: (props) => <Icons.Serial {...props} />, text: 'IDENTIFIER' },
    ASSOCIATION: {
      icon: (props) =>
        props.status === 'ERROR' ? (
          <Icons.AssociationError {...props} />
        ) : props.status === 'WRITING' ? (
          <LoadingContainer>
            <Spinner thickness="5px" size="xl" speed="0.65s" color="#000000" />
          </LoadingContainer>
        ) : (
          <Icons.Encoding {...props} />
        ),
      text: __(T.misc.association),
    },
  }

  status = {
    CONFIRMED: __(T.misc.confirmed),
    ERROR: __(T.error.error),
    TO_BE_READ: __(T.misc.reading) + '...',
    PROCESSING: __(T.misc.processing) + '...',
    IGNORED: __(T.misc.ignored),
    SKIPPED: __(T.misc.skipped),
    WRITING: __(T.misc.writing_progress) + '...',
    READY: __(T.misc.ready_to_read),
  }

  render() {
    const {
      marginBottom = 16,
      identifier,
      style,
      buttons,
      onInputSubmit,
      layout,
      isAssociation,
      identifiersCount,
      inLine,
      labelsByType,
      hideCode,
    } = this.props
    const isSimpleItemIdentifier =
      identifier.identifierType === 'SIMPLE_ITEM_IDENTIFIER' &&
      identifier._status !== 'CONFIRMED' &&
      identifier._status !== 'ERROR'
    let widthPerc =
      layout && layout.widthPerc
        ? layout.widthPerc
        : isSimpleItemIdentifier && !inLine
        ? 100
        : this.props.widthPerc || 100
    if (isAssociation && layout && identifiersCount) {
      widthPerc = inLine ? 100 : identifiersCount === 1 || identifiersCount % 2 == 0 ? 100 : 49
    }
    const isFullWidth = inLine || widthPerc === 100
    const iconSize = layout && layout.iconSize ? layout.iconSize : isFullWidth ? 80 : 60
    const iconStyle = {
      width: iconSize,
      height: iconSize,
      marginBottom: layout && layout.iconMarginBottom ? layout.iconMarginBottom : isFullWidth ? 0 : 10,
      marginRight: layout && layout.iconMarginRight ? layout.iconMarginRight : isFullWidth ? 30 : 0,
    }

    let textColor = this.textColor[identifier._status as any] ?? this.textColor.DEFAULT
    if (
      identifier.identifierType === 'ASSOCIATION' &&
      (identifier._status === 'ERROR' || identifier._status === 'NFC_COLLISION')
    )
      textColor = this.textColor.DEFAULT

    const idfType = this.identifierTypes[identifier.identifierType]
    const label = labelsByType?.[identifier.identifierType]
      ? labelsByType[identifier.identifierType]
      : identifier.role && identifier.role !== ''
      ? identifier.role
      : idfType?.text

    return (
      <IdentifierContainer
        data-testid={`identifier-box`}
        data-cy={identifier.code}
        status={identifier._status}
        identifierType={identifier.identifierType}
        style={style}
        pv={layout && layout.paddingVertical ? layout.paddingVertical : 10}
        ph={layout && layout.paddingHorizontal ? layout.paddingHorizontal : 30}
        row
        widthPerc={widthPerc}
        mb={marginBottom}
        minHeight={layout && layout.minHeight ? layout.minHeight : '175px'}
        maxHeight={layout && layout.maxHeight ? layout.maxHeight : '40vh'}
      >
        <Box vcenter flex row={(layout && layout.row) || isFullWidth}>
          {idfType && idfType.icon({ style: iconStyle, fill: textColor, status: identifier._status })}
          <Box flex={1}>
            {!isSimpleItemIdentifier && (
              <>
                <Text
                  bold
                  fontSize={layout && layout.fontSizeTagType ? layout.fontSizeTagType : 24}
                  style={{
                    marginBottom: isFullWidth ? 5 : 0,
                    color: textColor,
                  }}
                >
                  {label}
                </Text>
                {identifier.code && !hideCode && (
                  <Text
                    fontSize={layout && layout.fontSizeCode ? layout.fontSizeCode : 16}
                    bold
                    style={{ color: textColor }}
                  >
                    {identifier.code}
                  </Text>
                )}
                <Text
                  bold
                  fontSize={layout && layout.fontSizeStatus ? layout.fontSizeStatus : 20}
                  style={{ color: textColor }}
                >
                  {this.status[identifier._status ?? 'TO_BE_READ']}
                </Text>
              </>
            )}
            {!!isSimpleItemIdentifier && onInputSubmit && (
              <Input
                disabled={(identifier._status ?? 'TO_BE_READ') !== 'TO_BE_READ'}
                startFocus
                defaultValue={identifier.code}
                onEnter={onInputSubmit}
                label={`${!!identifier.role ? identifier.role : idfType?.text}`}
              />
            )}
            {identifier._error && (
              <Text fontSize={14} style={{ marginTop: 5, color: textColor }}>
                {identifier._error}
              </Text>
            )}
            {identifier._errorMessage && (
              <Text fontSize={14} style={{ marginTop: 5, color: textColor }}>
                {identifier._errorMessage}
              </Text>
            )}
          </Box>
        </Box>
        {buttons && (
          <Box center>
            {buttons?.map((button, index) => (
              <IdentifierButton key={index} title={button.text} onClick={button.onClick} />
            ))}
          </Box>
        )}
      </IdentifierContainer>
    )
  }
}

const IdentifierContainer = styled(Box)<{
  widthPerc: number
  status?:
    | 'CONFIRMED'
    | 'ERROR'
    | 'TO_BE_READ'
    | 'PROCESSING'
    | 'IGNORED'
    | 'SKIPPED'
    | 'WRITING'
    | 'NFC_COLLISION'
    | 'READY'
  identifierType?: IdentifierType
  minHeight?: string
  maxHeight?: boolean
}>`
  background-color: white;
  border-radius: 15px;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '225px')};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '40vh')};
  align-items: center;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%), inset 0px 0px 7px rgba(0, 0, 0, 0.1);
  width: ${({ widthPerc = 100 }) => (widthPerc === 100 ? `100%;` : `calc(${widthPerc}% - 4px);`)};
  ${({ status }) => status === 'ERROR' && `background-color: #EB5757;`}
  ${({ status }) => status === 'NFC_COLLISION' && `background-color: #EB5757;`}
  ${({ status }) => status === 'CONFIRMED' && `background-color: #75EBA8;`}
  ${({ status }) => status === 'IGNORED' && `background-color: #F2F2F2;`}
  ${({ status }) => status === 'SKIPPED' && `background-color: #F2F2F2;`}
  ${({ status }) => status === 'PROCESSING' && `box-shadow:inset 0 0px 10px rgb(117 235 168 / 70%);`}
  ${({ status }) => status === 'WRITING' && `box-shadow:inset 0 0px 10px rgb(245 167 66 / 70%);`}
  ${({ status, identifierType }) =>
    status === 'ERROR' && identifierType === 'ASSOCIATION' && `background-color: #E0E0E0;`}
     ${({ status = 'TO_BE_READ', identifierType }) =>
    status === 'TO_BE_READ' && identifierType === 'SIMPLE_ITEM_IDENTIFIER' && `background-color: #E0E0E0;`}
`

const IdentifierButton = styled((props) => <Button {...props} />)`
  background: #333333;
  color: white;
  border-radius: 100px;
  flex: 0;
  width: fit-content;
  min-width: 200px;
  padding: 0px 50px;
`

const LoadingContainer = styled(Box)`
  width: 80px;
  height: 80px;
  margin-bottom: 0px;
  margin-right: 30px;
  justify-content: center;
  align-items: center;
`
