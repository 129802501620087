import Base, { ShippingState } from 'core/pages/Shipping/ShippingReading'
import { DecodedItem, IdentifierType, ProductInfoField, Shippings } from 'stylewhere/api'
import { FormSchemaForm } from 'stylewhere/components'
import { ShippingExtensions } from 'stylewhere/extensions'
import { FormSchemaData, getDataFromSchema, OperationReadingProps, RfidReader } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { showToastError } from 'stylewhere/shared/utils'

interface CustomShippingState extends ShippingState {
  renderIteration: number
  formSubmitted: boolean
}

export default class ShippingReading extends Base<OperationReadingProps<CustomShippingState>, CustomShippingState> {
  state: CustomShippingState = {
    ...this.state,
    renderIteration: 0,
    formSubmitted: false,
  }

  alertOnNfcTagRead =
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.operation.attributes['alertOnNfcTagRead'] && this.operation.attributes['alertOnNfcTagRead'] === 'true'

  singleShippingScreen =
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.operation.attributes['singleShippingScreen'] && this.operation.attributes['singleShippingScreen'] === 'true'

  schema = ShippingExtensions.formSchema(this.operation)

  kickBackCondition = () => {
    return !this.singleShippingScreen && !this.locationState.formData
  }

  disableFormData = () => {
    return this.singleShippingScreen
  }

  _goBack = this.goBack.bind(this)

  goBack = () => {
    if (this.singleShippingScreen) {
      this.goDashboard()
    } else {
      this._goBack()
    }
  }

  decodedItemCheck = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    if (this.alertOnNfcTagRead && Object.keys(itemMapFromReader).some((tagCode) => tagCode.startsWith('E0'))) {
      showToastError(__(T.custom.nfcDetectedPleaseUseProductControl), __(T.error.error), true)
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      return false
    }
    return true
  }

  onFormSubmit = async (formData: FormSchemaData) => {
    let parcelCode
    const { autostartAntenna, barcodeItemReading } = this.state
    this.setState({ formData }, async () => {
      try {
        const payloadStartParcel: any = getDataFromSchema(formData, this.schema)
        parcelCode = payloadStartParcel.parcelCode
        const decodeRequest = this.getDecodeRequest()
        RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, decodeRequest)
        const parcel = await Shippings.startParcel(
          this.operation,
          payloadStartParcel,
          this.operation.startParcelWithAsn
        )

        const entriesItem: DecodedItem[] = []
        let tagsToAdd: string[] = []
        if (parcel && parcel.parcelEntries && parcel.parcelEntries.length > 0) {
          parcel.parcelEntries.map((entry) => {
            if (entry.item && entry.item !== null) {
              const uhfTag = entry.item.identifiers.filter((e) => e.type === 'UHF_TAG').flatMap(({ code }) => code)
              tagsToAdd = tagsToAdd.concat(uhfTag)
              entriesItem.push({
                item: entry.item,
                statuses: [],
                identifierCode: uhfTag.length > 0 ? uhfTag[0] : '',
              })
            }
          })
        }

        this.setState({ formData, parcel, items: entriesItem, initReaderTags: tagsToAdd, formSubmitted: true })
        if (autostartAntenna && !barcodeItemReading) {
          this.antennaRef.current.startReader()
        }
      } catch (err) {
        if (
          await ShippingExtensions.checkPackingUnpack(
            err as any,
            parcelCode,
            this.operation,
            () => this.onFormSubmit(formData),
            () => this.setState({ renderIteration: this.state.renderIteration + 1 })
          )
        )
          return
        showToastError(err, 'Start Parcel Error', this.isModal)
      }
    })
  }

  antennaStartButtonEnabled = () => {
    return !this.singleShippingScreen || this.state.formSubmitted
  }

  antennaClearButtonVisible = () => {
    return false
  }

  resetScreen = async () => {
    await this.antennaRef.current.stopReader()
    RfidReader.clear()
    this.setState({
      parcel: undefined,
      asn: undefined,
      items: [],
      formData: {},
      tags: undefined,
      formSubmitted: false,
      renderIteration: this.state.renderIteration + 1,
    })
  }

  canAutoStartAntenna = () => {
    return !this.singleShippingScreen
  }

  overrideProductInfoFields: () => ProductInfoField[] | undefined = () => {
    return []
  }

  confirmButtonEnabled = () => {
    return this.parcelIsSuitableForConfirmation()
  }

  getHideIdentifierZones: () => boolean = () => {
    return true
  }

  getHideIdentifierCodeByType = (): IdentifierType[] | undefined => {
    return ['NFC_TAG', 'UHF_TAG']
  }

  getDisableModalRowCounters = () => {
    return true
  }

  _afterConfirmSuccess = this.afterConfirmSuccess.bind(this)

  afterConfirmSuccess = async () => {
    if (this.singleShippingScreen) {
      if (['hideSuccessModal', 'changeInput', 'disabled'].includes(this.operation.postConfirmAction)) {
        this.setState({ renderIteration: this.state.renderIteration + 1 }, this.resetScreen)
      } else {
        //keepInput not managed
      }
    } else {
      this._afterConfirmSuccess()
    }
  }

  upperSideBarComponent: () => JSX.Element = () => {
    if (!this.singleShippingScreen) return <></>
    return (
      <FormSchemaForm
        key={this.state.renderIteration}
        schema={this.schema}
        submitText={this.state.formSubmitted ? undefined : __(T.misc.next)}
        onSubmit={this.onFormSubmit}
        onCancel={this.resetScreen}
        cancelText={__(T.misc.clear)}
        formContext="start"
        disabled={this.state.formSubmitted}
        operation={this.operation}
        style={{ paddingBottom: 20, width: '100%' }}
        disabledSpacerAfterFields
      />
    )
  }

  getBarcodeModeOptions: () => { separateValidations?: boolean | undefined } | undefined = () => {
    return { separateValidations: true }
  }

  render(): JSX.Element {
    return super.render()
  }
}
